<template>
  <section class="warning-wrap">
    <!-- <side-bar/>
    <section class="warning-content"> -->
      <page-header></page-header>
      <router-view />
    <!-- </section> -->
  </section>
</template>

<script>
import SideBar from '@/components/SideBar'
import pageHeader from '@/components/pageHeader'
export default {
  components: {
    SideBar, pageHeader
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/index';
.warning-wrap {
  width: 100%;
  height: calc(100% - 80px);
  /* padding: 30px 30px;
  display: flex;
  .warning-content {
    width: 100%;
    min-height: $contentHeight;
    margin-left: 30px;
  } */
}
</style>
